import {cva} from 'class-variance-authority';
import type {HTMLAttributes} from 'react';
import {normalizeName} from '@shopify/dux';

import {SectionErrorBoundary} from '@/components/base/layouts/Section/SectionErrorBoundary';
import type {Mode} from '@/components/base/types';
import {twMerge} from '@/stylesheets/twMerge';

const styles = cva('grid gap-y-2xl grid-cols-full', {
  variants: {
    bottomSpacing: {
      xl: 'pb-xl',
      '2xl': 'pb-2xl',
      '4xl': 'pb-4xl',
      none: 'pb-0',
    },
    mode: {
      light: 'bg-section-light-bg text-section-light-text',
      dark: 'bg-section-dark-bg text-section-dark-text',
    },
    topSpacing: {
      xl: 'pt-xl',
      '2xl': 'pt-2xl',
      '4xl': 'pt-4xl',
      none: 'pt-0',
    },
  },
  defaultVariants: {
    bottomSpacing: '4xl',
    mode: 'light',
    topSpacing: '4xl',
  },
});

type SectionSpacing = 'xl' | '2xl' | '4xl' | 'none';

export interface SectionProps extends HTMLAttributes<HTMLElement> {
  bottomSpacing?: SectionSpacing;
  componentName?: string;
  mode?: Mode;
  sectionName?: string;
  topSpacing?: SectionSpacing;
  errorBoundaryFullWidth?: boolean;
  navbarPadding?: boolean;
}

export default function Section({
  bottomSpacing = '4xl',
  children,
  className = '',
  componentName = '',
  mode = 'light',
  sectionName = '',
  topSpacing = '4xl',
  errorBoundaryFullWidth = false,
  navbarPadding = false,
  ...props
}: SectionProps) {
  const domAttrs = Object.fromEntries(
    Object.entries(props).filter(([key]) =>
      Boolean(key.match(/^(aria|role|data-)/g)),
    ),
  );
  const normalizedSectionName = normalizeName(sectionName || '');
  const normalizedComponentName =
    normalizeName(componentName || '') || normalizedSectionName;

  return (
    <SectionErrorBoundary
      fullWidth={errorBoundaryFullWidth}
      navbarPadding={navbarPadding}
    >
      <section
        className={twMerge(
          styles({bottomSpacing, mode, topSpacing}),
          className,
        )}
        data-section-name={normalizedSectionName}
        data-component-name={normalizedComponentName}
        data-viewable-component
        data-mode={mode}
        {...domAttrs}
        {...props}
      >
        {children}
      </section>
    </SectionErrorBoundary>
  );
}
